.bg {

  &-color {

    background-color: var(--primary);

    @include media-breakpoint-up(lg) {

      background-color: $body-bg;
    }
  }

  &-primary-gradient {

    background: linear-gradient(180deg, var(--primary-darken-1) 0%, var(--primary) 100%);
  }

  &-texture {

    background: url('/images/bg-texture.png');
    opacity: .20;
  }
}

.inputFile {

  opacity: 0;
  z-index: -1;
  top: 0;
}

.svg-lines {

  //height: 55px;
  //fill: $dark-primary-7;
  fill: var(--primary-darken-1);
}

.svg-lines-primary {

  //height: 55px;
  //fill: $dark-primary-7;
  fill: var(--primary);
}

.height-limit {

  width: convert-to-rem(198px);
  height: convert-to-rem(198px);
  overflow: hidden;
}

.ic {

  &-question {

    width: 26px;
    height: 26px;
  }

  &-cupom {

    width: 27px;
    height: 38px;
  }

  &-close {

    width: 19px;
    height: 19px;
    fill: var(--primary);
  }

  &-social {

    width: 25px;
    height: 25px;
    fill: var(--secondary);
  }
}

.hover-cursor {

  @include media-breakpoint-up(lg) {

    &:hover {

      cursor: pointer;
    }
  }
}

.after-line {

  ::after {

    content: '';
    width: 147px;
    background-color: var(--secondary);
    height: 4px;
    display: block;
    margin: .5rem auto 0 auto;
  }

  &-secondary {
    &::after {
      content: '';
      width: 60px;
      height: 4px;
      background-color: $secondary;
      display: block;
      margin: .5rem auto 0 auto;

      @include media-breakpoint-up(lg) {

        margin-left: 0;
      }
    }

    &-auto {
      &::after {
        margin: auto;
      }
    }
  }
}

.svg-arrow-down {

  fill: var(--primary);
  width: convert-to-rem(25px);
  height: convert-to-rem(38px);
  animation: arrowDown .5s infinite alternate;
}

@keyframes arrowDown {
  to {

    transform: translateY(10px);
  }
}

.text-underline {

  text-decoration: underline;
}

.go-down {

  position: absolute;
  bottom: 0;
  width: 100%;
}

.highlight-title {

  &:after {

    content: '';
    background: var(--primary-darken-1);
    height: 4px;
    width: 145px;
    display: block;
    margin: 1.5rem auto;
  }
}
