// Project's common (global) styles

// Generate responsive font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $font-size in $font-sizes {
            .fs#{$infix}-#{$font-size} {
                font-size: #{$font-size}px;
            }
        }
    }
}

//Generate letter-spacing and line-height with grid-breakpoint to use in html
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (letter-spacing: ls, line-height: lh) {
            @each $size, $length in $spacers {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }

                @if $size != 0 {
                    .#{$abbrev}#{$infix}-n#{$size} {
                        #{$prop}: -$length !important;
                    }
                }
            }
        }
    }
}

// Generate stroke and fill for each color in theme, including links.
@each $color, $value in $theme-colors {

    .stroke-#{$color} {

        stroke: $value;
    }

    .fill-#{$color} {

        fill: $value;
    }

    @include media-breakpoint-up(md) {

        a {

            @include hover {

                .stroke-#{$color} {

                    stroke: darken($value, 15%);
                }

                .fill-#{$color} {

                    fill: darken($value, 15%);
                }
            }
        }
    }
}

.primary {

    &-theme-bg {

        background-color: var(--primary);
    }

    &-theme-bg-dark-1 {

        background-color: var(--primary-darken-1);
    }

    &-theme-bg-dark-2 {

        background-color: var(--primary-darken-2);
    }

    &-text {

        color: var(--primary);
    }

    &-border {

        border-color: var(--primary) !important;
    }

    &-text-dark-1 {

        color: var(--primary-darken-1);
    }
}

.secondary {

    &-theme-bg {

        background-color: var(--secondary);
    }

    &-text {

        color: var(--secondary);
    }

    &-btn {

        background-color: var(--secondary);
        text-transform: uppercase;
        border-bottom-width: $border-btn-variant-width;
        border-bottom-color: rgba(0, 0, 0, .3);

        &:hover {

            background-color: var(--secondary);
        }
    }

    &-fill {

        fill: var(--secondary);
    }
}

.img-cover-width {
    width: 100%;
    object-fit: cover;
}

.opacity-50 {
    opacity: .50;
}
