.btn {

  border-radius: 0 1.25rem 0 1.25rem;

  &-warning {

    text-transform: uppercase;
    border-bottom-width: $border-btn-variant-width;
    border-bottom-color: darken($warning, 5%);

    &:hover {

      background-color: $warning;
    }
  }
}
